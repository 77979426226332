import type { Meta, StoryObj } from "@storybook/react";
import Header from "../components/Layout/Header";

const meta = {
    title: "Example/Header",
    component: Header,
    argTypes: {
        backgroundColor: { control: "color" },
    },
} as Meta;

export default meta;
type Story = StoryObj<typeof meta>;

export const HeaderDark: Story = {
    args: {
        toggleColor: "white",
        backgroundColor: "bg-darkGray",
        // logoUrl: "/logoRBWhite.png",
        // logoMobileUrl: "/logoRBMobileWhite.svg",
    },
};

export const HeaderLight: Story = {
    args: {
        backgroundColor: "bg-white",
        backgroundNavColor: "bg-white",
        logoUrl: "/logoRBBlack.svg",
        logoMobileUrl: "/logoRBMobileBlack.svg",
        barsUrl: "/Icons/Header/barsBlack.svg",
        dropdownUrl: "/Icons/Header/dropdownBlack.svg",
        textColor: "text-black",
        xUrl: "/Icons/Header/xblack.svg",
        arrowSrc: "/Icons/arrowRBlack.svg",
        // arrowHoverSrc: "/Icons/Header/arrowRWhite.svg",
        arrowHoverSrc: "/Icons/Header/arrowRYellow.svg",
        textColorHover: "text-black",
        toggleColor: "black",
    },
};
export const HeaderYellow: Story = {
    args: {
        backgroundColor: "bg-yellow",

        backgroundNavColor: "bg-yellow",
        logoUrl: "/logoRBBlack.svg",
        logoMobileUrl: "/logoRBMobileBlack.svg",
        barsUrl: "/Icons/Header/barsBlack.svg",
        dropdownUrl: "/Icons/Header/dropdownBlack.svg",
        textColor: "text-black",
        xUrl: "/Icons/Header/xblack.svg",
        arrowSrc: "/Icons/arrowRBlack.svg",
        arrowHoverSrc: "/Icons/Header/arrowRWhite.svg",
        textColorHover: "text-white",
    },
};
export const HeaderTurquoise: Story = {
    args: {
        backgroundColor: "bg-turquoise",
        backgroundNavColor: "bg-turquoise",
        logoUrl: "/logoRBBlack.svg",
        logoMobileUrl: "/logoRBMobileBlack.svg",
        barsUrl: "/Icons/Header/barsBlack.svg",
        dropdownUrl: "/Icons/Header/dropdownBlack.svg",
        textColor: "text-black",
        xUrl: "/Icons/Header/xblack.svg",
        arrowSrc: "/Icons/arrowRBlack.svg",
    },
};
export const HeaderBlue: Story = {
    args: {
        backgroundColor: "bg-blue",
        backgroundNavColor: "bg-blue",
        logoUrl: "/logoRBBlack.svg",
        logoMobileUrl: "/logoRBMobileBlack.svg",
        barsUrl: "/Icons/Header/barsBlack.svg",
        dropdownUrl: "/Icons/Header/dropdownBlack.svg",
        textColor: "text-black",
        xUrl: "/Icons/Header/xblack.svg",
        arrowSrc: "/Icons/arrowRBlack.svg",
    },
};
export const HeaderPink: Story = {
    args: {
        backgroundColor: "bg-pink",
        backgroundNavColor: "bg-pink",
        logoUrl: "/logoRBBlack.svg",
        logoMobileUrl: "/logoRBMobileBlack.svg",
        barsUrl: "/Icons/Header/barsBlack.svg",
        dropdownUrl: "/Icons/Header/dropdownBlack.svg",
        textColor: "text-black",
        xUrl: "/Icons/Header/xblack.svg",
        arrowSrc: "/Icons/arrowRBlack.svg",
    },
};
export const HeaderTransparent: Story = {
    args: {
        backgroundColor: "bg-transparent",
        backgroundNavColor: "bg-transparent",
        logoUrl: "/logoRBBlack.svg",
        logoMobileUrl: "/logoRBMobileBlack.svg",
        barsUrl: "/Icons/Header/barsBlack.svg",
        dropdownUrl: "/Icons/Header/dropdownBlack.svg",
        textColor: "text-black",
        xUrl: "/Icons/Header/xblack.svg",
        arrowSrc: "/Icons/arrowRBlack.svg",
    },
};
export const HeaderNoColor: Story = {
    args: {
        backgroundColor: "bg-none",
        backgroundNavColor: "bg-black",
        textColor: "text-white",
        arrowSrc: "/Icons/Header/arrowRWhite.svg",
        toggleColor: "white",
        textColorHover: "text-yellow",
    },
};
