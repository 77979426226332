"use client";

import { Drawer } from "@mantine/core";
import { useRouter } from "next/navigation";
import { useState } from "react";
import Button from "./Cards/Button";
import { HeaderDark } from "@/stories/Header.stories";
import Header from "./Layout/Header";
import { useForm } from "@mantine/form";
import slugify from "slugify";
import { newestCategories } from "@/utils";
import Link from "next/link";

interface CategoryMenuProps {
    query?: string;
}

export default function CategoryMenu({ query }: CategoryMenuProps) {
    const [open, setOpen] = useState(false);
    const router = useRouter();

    const form = useForm({
        initialValues: {
            search: "",
        },
    });

    return (
        <>
            <Drawer
                opened={open}
                onClose={() => setOpen(false)}
                withCloseButton={false}
                position="top"
                size={"100%"}
                transitionProps={{
                    duration: 500,
                    transition: "slide-down",
                    timingFunction: "ease",
                }}
                styles={{ content: { backgroundColor: "#0D0D0D" } }}
            >
                <div className="bg-darkGray text-white space-y-10 pt-5 lg:pt-20">
                    <Header
                        backgroundColor={"bg-darkGray"}
                        logoUrl={HeaderDark.args?.logoUrl}
                        logoMobileUrl={HeaderDark.args?.logoMobileUrl}
                        barsUrl={HeaderDark.args?.barsUrl}
                        dropdownUrl={HeaderDark.args?.dropdownUrl}
                        textColor={HeaderDark.args?.textColor}
                        xUrl={HeaderDark.args?.xUrl}
                        arrowSrc={HeaderDark.args?.arrowSrc}
                        toggleColor={HeaderDark.args?.toggleColor}
                    />
                    {/* 
                    we may need it later
                    <div className="space-y-36 px-3 lg:px-20 ">
                        <form
                            onSubmit={form.onSubmit((values) => {
                                router.push(`/de/suche/${values.search}`);
                            })}
                        >
                            <TextInput
                                id="search"
                                label=""
                                name="search"
                                type="text"
                                placeholder="Suche"
                                backgroundColor="bg-darkGray"
                                placeholderColor="placeholder-white"
                                width="w-full"
                                height="h-12"
                                hasIcon={true}
                                iconSrc="/Icons/search.svg"
                                ring=""
                                border="border-b border-gray-50"
                                textColor="text-white"
                                placeholderPadding="pl-8"
                                {...form.getInputProps("search")}
                            />
                        </form>
                    </div>
                    */}
                    <div className="container lg:space-y-5">
                        <div className="container max-w-[60rem] px-5 lg:px-0">
                            <div className="flex justify-between">
                                <div className="text-white text-xl lg:text-5xl font-bold leading-10">
                                    Blogkategorien
                                </div>
                                <button onClick={() => setOpen(false)} type="button">
                                    <img
                                        src="/Icons/close.svg"
                                        className="w-8 h-8 relative"
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="lg:px-[15rem] px-5">
                            <div className="divide-y space-y-6 lg:w-[50rem]">
                                {newestCategories.map((item) => (
                                    <div
                                        key={item.category.name}
                                        className="lg:py-4 py-2"
                                    >
                                        <Link
                                            href={`/de/category/${slugify(
                                                item.category.slug,
                                                { lower: true },
                                            )}`}
                                        >
                                            <span className="text-white hover:text-yellow text-2xl lg:text-3xl py-2 font-bold leading-7 lg:leading-10">
                                                {item.category.name}
                                            </span>
                                        </Link>
                                        <div>
                                            {item.subCategories.map(
                                                (subCategory, index) => (
                                                    <Link
                                                        key={
                                                            item.subCategories[index]
                                                                .name
                                                        }
                                                        href={`/de/category/${slugify(
                                                            item.category.slug,
                                                            { lower: true },
                                                        )}/${slugify(subCategory.slug, {
                                                            lower: true,
                                                        })}`}
                                                        className="text-white hover:text-yellow text-base font-normal leading-tight"
                                                    >
                                                        {subCategory.name}
                                                        <span className="px-3 text-white">
                                                            |
                                                        </span>
                                                    </Link>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>

            <Button
                onClick={() => setOpen(true)}
                hasIcon={true}
                iconSrc="/Icons/Header/barsBlack.svg"
                backgroundColor="bg-none"
                border="border-none"
                text="Blogkategorien"
                textColor="text-black"
                textSize="lg:text-center text-start text-black text-2xl font-normal"
                iconPlacement="right"
                paddings="py-3"
                alignment="lg:justify-center justify-start"
            />
        </>
    );
}
