import InfoModal from "./Modal";

interface ErrorModalProps {
    open: boolean;
    onClose: () => void;
}
export default function ErrorModal({ open, onClose }: ErrorModalProps) {
    return (
        <InfoModal opened={open} onClose={onClose} size="lg" paddings="p-10">
            <div className="space-y-6">
                <p className="text-xl">Hallo! </p>
                <p className="text-xl leading-7 text-justify">
                    Leider konnte die Nachricht nicht gesendet werden. Bitte versuche es
                    noch einmal zu einem späteren Zeitpunkt oder melde dich direkt
                    telefonisch beim Support-Team unter der Nummer +41 (0)71 220 90 64.
                </p>
                <p className="text-xl">Liebe Grüsse</p>
                <p className="text-xl">Support-Team Rico Brunner</p>
            </div>
        </InfoModal>
    );
}
